<template>
    <div class="page-wrap">
        <h1>您访问的页面不存在!</h1>
        <Button class="back-home" @click="handleClick">返回首页</Button>
    </div>
</template>

<script>
export default {
    methods: {
        handleClick() {
            this.$router.push('/home');
        }
    }
};
</script>

<style lang="less" scoped>
.page-wrap {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 350px;
    background: url('~@/assets/images/404.png') center 100px no-repeat;
}
.back-home {
    margin-top: 20px;
}
</style>